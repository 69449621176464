import styled from 'styled-components';
import { rem } from 'polished';

export const StyledDisqus = styled.div`
  .disqus__header {
    display: flex;
    margin-bottom: ${rem(`8px`)};
    pointer-events: none;
    ${({ theme }) => theme.breakpoints.desktop`
      justify-content: center;
      margin-bottom: ${rem(`36px`)};
    `}
  }
  .disqus_content {
    #disqus_thread button {
      color: blue;
      background: red;
    }
  }
`;
