import styled from 'styled-components';
import { rem } from 'polished';

function parseSlug(slug: string) {
  return slug
    .replace(`/blog/articles/`, ``)
    .replace(`/`, ``)
    .split(`-`)
    .join(` `);
}

interface IStyledArticleNavigation {
  toSlug: string;
  next: boolean;
}

export const StyledArticleNavigation = styled.div<IStyledArticleNavigation>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${rem(`32px`)};
  height: ${({ theme }) => theme.rhythmSizing(10)}rem;
  cursor: pointer;
  position: relative;
  background-color: ${({ theme }) => theme.colors.blue_1_65};
  transition: background-color 300ms ease-in-out;
  ${({ theme, toSlug }) => theme.breakpoints.tablet`
    &:hover {
      background-color: ${theme.colors.blue_1};
      &::after {
        width: 250px;
        opacity: 1;
        visibility: visible;
        content: '${parseSlug(toSlug)}';
      }
    }
    &::after {
      width: 0;
      content: '';
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      opacity: 0;
      visibility: hidden;
      font-size: 24px;
      background-color: ${theme.colors.blue_1};
      color: ${theme.colors.white_1};
      position: absolute;
      right: ${({ next }) => (next ? `100%` : `initial`)};
      left: ${({ next }) => (next ? `initial` : `100%`)};
      top: 0;
      bottom: 0;
      margin: auto;
      z-index: 9999;
      transition: width 300ms ease-in-out, opacity 500ms ease-in-out, visibility 500ms ease-in-out;
    }
  `}
  a {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    button {
      width: 100%;
      height: 100%;
    }
  }
  &.articleNavigation--disabled {
    background-color: ${({ theme }) => theme.colors.gray_3_65};
  }
`;
