import { AnchorLink } from 'gatsby-plugin-anchor-links';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromLine } from '@fortawesome/pro-light-svg-icons';
import styled from 'styled-components';
import { rem } from 'polished';
import { kebabCase } from 'lodash';
import { Text } from '../../../components/Text';

export const StyledTableOfContent = styled.div`
  .toc-header {
    margin-bottom: ${rem(`12px`)};
  }
  .article-anchors {
    max-width: ${rem(`315px`)};
    position: sticky;
    top: 75px;
  }
  .anchors-list__item {
    margin-bottom: ${rem(`6px`)};
  }
  .anchor-label-container {
    position: relative;
    padding-left: ${rem(`20px`)};
  }
  .link {
    margin-bottom: ${rem(`12px`)};
    &__icon {
      margin-left: ${rem(`6px`)};
    }
    &:hover {
      .anchor-title {
        transition: color 200ms ease-in-out;
        color: ${({ theme }) => theme.colors.pink_1};
      }
    }
  }
  .anchor-icon {
    color: ${({ theme }) => theme.colors.pink_1};
    position: absolute;
    left: 0;
    top: ${rem(`4px`)};
  }

  .anchor-title {
    transition: color 200ms ease-in-out;
    color: ${({ theme }) => theme.colors.gray_3};
  }

  .highlight-anchor {
    color: ${({ theme }) => theme.colors.pink_1};
    transition: 200ms color ease-in-out;
  }

  ${({ theme }) => theme.breakpoints.desktop`
    &:hover svg {
      color: ${theme.colors.pink_1};
    }
  `}
`;

interface ITableOfContent {
  className?: string;
  rawContent?: any;
  slug: string;
  headerPosition: any;
}

export const TableOfContent = ({
  className,
  rawContent,
  slug,
  headerPosition,
}: ITableOfContent) => {
  const myRe = /(?<!#)#{1}[\s](.+?)(?:\\n+|\n+)/gm;
  const myResult = rawContent.matchAll(myRe);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const myArray = Array.from(myResult, ([_, value]) => ({
    key: kebabCase(value.toLowerCase()),
    label: value,
  }));

  return (
    <StyledTableOfContent className={className}>
      <Text noRhythem type="title4" className="toc-header">
        Table of content
      </Text>
      <div className="article-anchors">
        {myArray.map(({ key, label }) => (
          <AnchorLink
            key={key}
            to={`/blog/${slug}#${key}`}
            title={label}
            stripHash
            className="anchors-list__item"
          >
            <Text
              type="body3"
              tag="div"
              color="gray_3"
              className="link"
              noRhythem
            >
              <div className="anchor-label-container">
                <FontAwesomeIcon
                  className="anchor-icon"
                  size="1x"
                  icon={faArrowRightFromLine}
                />

                <span
                  className={`anchor-title ${
                    headerPosition === key ? `highlight-anchor` : ``
                  }`}
                >
                  {label}
                </span>
              </div>
            </Text>
          </AnchorLink>
        ))}
      </div>
    </StyledTableOfContent>
  );
};
