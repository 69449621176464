import styled from 'styled-components';
import { rem } from 'polished';

interface IStyledArticle {
  theme: any;
  maskImgBg: string;
  maskImgLeft: string;
  maskImgRight: string;
}

export const StyledArticle = styled.div<IStyledArticle>`
  .body {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
  }
  .main {
    flex: 0 1 auto;
  }
  && .heading .content {
    padding-top: ${rem(60)};
  }
  .spacer {
    flex: 1 0 24px;
  }
  .aside {
    flex: 1 0 350px;
    max-width: 350px;
  }
  .cta {
    margin-bottom: ${rem(`24px`)};
  }
  && .layout-wide__top .center-shape {
    background: red;
  }

  .article-meta {
    position: absolute;
    bottom: calc(100% + ${rem(`-48px`)});
    width: 100%;
    height: ${rem(`120px`)};
    z-index: 999;
    .author {
      position: absolute;
      top: ${rem(`-42px`)};
    }
  }

  .layout-wide__top {
    background-size: cover;
    mask-size: 1920px 700px, 0% 0px, 0% 0px;
    mask-position: center top, left top, right top;
    mask-repeat: no-repeat, no-repeat, no-repeat;
    mask-image: url(${({ maskImgBg }) => maskImgBg}),
      url(${({ maskImgLeft }) => maskImgLeft}),
      url(${({ maskImgRight }) => maskImgRight});
    @media (min-width: 1920px) {
      mask-size: 95% 700px, 2.5% 700px, 2.5% 617px;
    }
    @media (min-width: 2020px) {
      mask-size: 90% 700px, 5% 700px, 5% 617px;
    }
    @media (min-width: 2220px) {
      mask-size: 85%  700px, 7.5% 700px, 7.5% 617px;
    }
    @media (min-width: 2420px) {
      mask-size: 80% 700px, 10% 700px, 10% 617px;
    }
    @media (min-width: 2620px) {
      mask-size: 75% 700px, 12.5% 700px, 12.5% 617px;
    }
    @media (min-width: 2820px) {
      mask-size: 70% 700px, 15% 700px, 15% 617px;
    }
    @media (min-width: 3020px) {
      mask-size: 65% 700px, 17.5% 700px, 17.5% 617px;
    }
    @media (min-width: 3220px) {
      mask-size: 60% 700px, 20% 700px, 20% 617px;
    }
    @media (min-width: 3420px) {
      mask-size: 55% 700px, 22.5% 700px, 22.5% 617px;
    }
    @media (min-width: 3620px) {
      mask-size: 50% 700px, 25% 700px, 25% 617px;
    }
  }

  .top-container {
    min-height: ${rem(`724px`)};
  }

  .top-container .heading {
    grid-template-columns: 1fr;
  }

  .top-container .heading .content > div {
    max-width: 85%;
  }

  .main-title {
    display: inline;
    background-color ${({ theme }) => theme.colors.blue_1_65};
    color: ${({ theme }) => theme.colors.white_1_75};
    line-height: 1.75;
    padding: 0 ${rem(`36px`)};
    box-decoration-break: clone;
    border-radius: ${rem(`16px`)};
  }

  .article-content-container {
    width: 100%;
    text-align: left;
    margin-bottom: ${rem(`48px`)};
    padding: ${rem(`24px`)};
    border-radius: ${rem(`12px`)};
    background-color: ${({ theme }) => theme.colors.blue_1_65};
    color: ${({ theme }) => theme.colors.gray_3};
    &:last-child {
      margin-bottom: 0;
    }
    > div {
      margin-bottom: 0;
    }
  }

  .article-content-column-container {
    display: flex;
    width: 100%;
    text-align: left;
    margin-bottom: ${rem(`48px`)};
  }


  deckgo-highlight-code, deckgo-highlight-code-edit {
    --deckgo-highlight-code-scroll: hidden;
    --deckgo-highlight-code-carbon-header-button-red-background: ${({
      theme,
    }) => theme.colors.blue_3_45};
    --deckgo-highlight-code-carbon-header-button-yellow-background: ${({
      theme,
    }) => theme.colors.blue_3_45};
    --deckgo-highlight-code-carbon-header-button-green-background: ${({
      theme,
    }) => theme.colors.blue_3_45};
    --deckgo-highlight-code-line-height: 1.325;
    --deckgo-highlight-code-carbon-background: ${({ theme }) =>
      theme.colors.blue_1};
  }

  .article-content__left {
    flex: 0 1 auto;
    display:flex;
    flex-direction: column;
    flex-wrap: nowrap;
    color: ${({ theme }) => theme.colors.gray_3};
    &:last-child {
      margin-bottom: 0;
    }
    > div {
      margin-bottom: 0;
    }
  }

  .article-content__left-content {
    flex: 1 0 auto;
    padding: ${rem(`24px`)};
    padding-right: ${rem(`48px`)};
    border-radius: ${rem(`12px`)};
    background-color: ${({ theme }) => theme.colors.blue_1_65};
  }

  .article-content__next-prev {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: ${rem(`48px`)};
    margin-top: ${rem(`48px`)};
    margin-bottom: ${rem(`48px`)};
  }

  .article-content__seperator {
    flex: 1 0 48px;
  }

  .article-content__right {
    flex: 1 0 315px;
    position: relative;
    padding: ${rem(`24px`)};
    padding-left: ${rem(`48px`)};
    border-radius: ${rem(`12px`)};
    background-color: ${({ theme }) => theme.colors.blue_1_65};
    color: ${({ theme }) => theme.colors.gray_3};
  }

  .hero-container {
    margin-top:${rem(`12px`)};
  }

  .back-button {
    margin-bottom: ${rem(`24px`)};
    color: ${({ theme }) => theme.colors.white_1_45};
    padding: ${rem(`8px`)} ${rem(`12px`)};
    background-color: ${({ theme }) => theme.colors.blue_1_65};
    border-radius: ${rem(`12px`)};
    &:hover {
      color: ${({ theme }) => theme.colors.pink_1};
    }
    svg path {
      fill: ${({ theme }) => theme.colors.pink_1};
    }
  }

  .back-content {
    margin-left: ${rem(`4px`)};

  }
`;
