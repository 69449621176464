import React, { useEffect, useRef, useState } from 'react';
import { BreakpointContext, useBreakpoints } from 'react-use-breakpoints';
import { defineCustomElements as deckDeckGoHighlightElement } from '@deckdeckgo/highlight-code/dist/loader';
import { graphql, Link } from 'gatsby';
import { dropWhile, get, isNil } from 'lodash';
import { getImage } from 'gatsby-plugin-image';
import { Social } from '@/components/Social';
import { CallToAction } from '@/components/CallToAction';
import useScrollPosition from '@/hooks/useScrollPosition';
import {
  faArrowLeftLong,
  faArrowRightLong,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ArticleThumb } from '@/components/ArticleThumb';
import { RelatedArticles } from '@/components/RelatedArticles';
import Layout, { Main, Aside } from '../../components/Layout';
import { Content, HTMLContent } from '../../components/Content';
import { Newsletter } from '../../components/Newsletter';
import { ArticleMeta } from '../../components/ArticleMeta';
import { Disqus } from '../../components/Disqus';
import { Hero } from '../../components/Hero';
import { Share } from '../../components/Share';
import { Seo } from '../../components/SEO';
import { Text } from '../../components/Text';
import { StyledArticle } from './styles';
import { TableOfContent } from './TableOfContent/TableOfContent';

deckDeckGoHighlightElement();

const isBrowser = typeof window !== `undefined`;

type SiblingArticle = {
  excerpt: string;
  title: string;
  image: any;
  slug: string;
};

interface IArticleTemplate {
  isPreview?: string;
  content?: any;
  rawContent?: any;
  title: string;
  slug: string;
  timeToRead?: number;
  date: string;
  author: { name: string; image: any };
  cover?: any;
  tags: string[];
  nextArticle?: SiblingArticle;
  prevArticle?: SiblingArticle;
  metaDescription: string;
  contentRef?: any;
  relatedArticles: any;
}

interface IArticle {
  pageContext: any;
  data: {
    file: {
      childImageSharp: {
        fixed: {
          aspectRatio: string;
          base64: string;
          sizes: any;
          src: string;
          srcSet: string;
        };
      };
    };
    site: {
      siteMetadata: {
        title: string;
        siteUrl: string;
      };
    };
    contentfulArticle: any;
    related: any;
  };
  location: object;
}

const ArticleTemplate = ({
  isPreview,
  content,
  rawContent,
  title,
  slug,
  timeToRead,
  date,
  author,
  cover,
  tags,
  nextArticle,
  prevArticle,
  metaDescription,
  contentRef,
  relatedArticles,
}: IArticleTemplate) => {
  const breakpoints = React.useContext(BreakpointContext);
  const { breakpoint } = useBreakpoints(breakpoints);
  const isUnderPhablet = [`watch`, `phone`, `tablet`, `phablet`].some(
    (item) => item === breakpoint,
  );

  const { y: scrollPositionY } = isBrowser ? useScrollPosition() : { y: 0 };
  const [tocList, setTocList] = useState<{ offset: number; name: string }[]>(
    [],
  );
  const [currentHeaderInPosY, setCurrentHeaderInPosY] = useState<string>(``);

  const relatedArticlesToDisplay = relatedArticles.map(({ node }) => {
    const { heroImage: nodeHeroImage } = node;
    const articleHeroImage = getImage(nodeHeroImage);
    return {
      title: node.title,
      content: node.excerpt,
      imageUrl: articleHeroImage,
      link: `/blog/${node.slug}`,
      isVideo: false,
      ...node,
    };
  });

  useEffect(() => {
    if (!contentRef?.current) {
      return;
    }
    const selected = dropWhile(
      tocList.map(({ offset, name }, idx, array) => {
        const isLast = array.length - 1 === idx;
        if (!isLast) {
          return offset <= scrollPositionY &&
            array[idx + 1].offset >= scrollPositionY
            ? name
            : null;
        }
        return offset <= scrollPositionY ? name : null;
      }),
      isNil,
    )[0];
    setCurrentHeaderInPosY(selected);
  }, [scrollPositionY, contentRef?.current, tocList]);

  useEffect(() => {
    if (!contentRef?.current) {
      return;
    }
    const list = contentRef.current.querySelectorAll(`h1`);
    const newList = [...list].map((item) => ({
      offset: item.offsetTop + 640, // 700 is the magic number of the height of the hero.
      name: item.id,
    }));
    setTocList(newList);
  }, [contentRef]);

  return (
    <>
      <ArticleMeta
        author={author}
        authorBig
        date={date}
        timeToRead={timeToRead}
        tags={tags}
        imageSize="xxl"
        className="article-meta"
        allTags
      />
      {isUnderPhablet ? (
        <Hero
          imageSrc={cover}
          nextArticleSlug={nextArticle.slug}
          prevArticleSlug={prevArticle.slug}
        />
      ) : null}

      <div>
        <div className="article-content-column-container">
          <div className="article-content__left">
            <div className="article-content__left-content">
              <slot user="wallE">
                <HTMLContent>{content}</HTMLContent>
              </slot>
            </div>
          </div>

          <div className="article-content__seperator" />

          <TableOfContent
            className="article-content__right"
            rawContent={rawContent}
            slug={slug}
            headerPosition={currentHeaderInPosY}
          />
        </div>

        {(prevArticle?.slug || nextArticle?.slug) && (
          <div className="article-content__next-prev">
            <div className="prev-article">
              {prevArticle?.slug && (
                <Link to={prevArticle.slug} className="prev-article__link">
                  <ArticleThumb
                    title={prevArticle.title}
                    thumbnail={prevArticle.image}
                    excerpt={prevArticle.excerpt}
                    navPosition="left"
                  >
                    <FontAwesomeIcon icon={faArrowLeftLong} />
                    <span>Prev article</span>
                  </ArticleThumb>
                </Link>
              )}
            </div>
            {nextArticle?.slug && (
              <div className="next-article">
                <Link to={nextArticle.slug} className="next-article__link">
                  <ArticleThumb
                    title={nextArticle.title}
                    thumbnail={nextArticle.image}
                    excerpt={nextArticle.excerpt}
                    navPosition="right"
                  >
                    <span>Next article</span>
                    <FontAwesomeIcon icon={faArrowRightLong} />
                  </ArticleThumb>
                </Link>
              </div>
            )}
          </div>
        )}

        {isPreview === `false` ? (
          <div className="article-content-container">
            <Share
              heading="Share"
              title={title}
              slug={`/blog/${slug}`}
              excerpt={metaDescription}
            />
          </div>
        ) : null}

        {isPreview === `false` ? (
          <div className="article-content-container">
            <Disqus heading="Feedback" title={title} slug={slug} />
          </div>
        ) : null}

        <RelatedArticles articles={relatedArticlesToDisplay} />

        <Newsletter id="newsletter_article" />
      </div>
    </>
  );
};

const ArticlePage = ({ data, location, pageContext }: IArticle) => {
  const article = get(data, `contentfulArticle`);
  const related = get(data, `related.edges`);
  // const site = get(data, `site`);
  const page = get(data, `articlePage`);
  const { svgTopPath, svgBottomPath, ctaTitle, ctaHookId, ctaIcon, ctaBody } =
    page;
  const {
    templateKey,
    title,
    body,
    slug,
    heroImage,
    metaTitle,
    metaDescription,
    author,
    tags,
    updateDate,
    timeToRead,
  } = article;
  const content = get(body, `childMarkdownRemark.html`);
  const markdownContent = body?.body;
  const { next, prev } = pageContext;
  const hero = getImage(heroImage);

  const svgExLeft = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" preserveAspectRatio="none" viewBox="0 0 100 1011"><rect fill="#000" x="0" y="0" width="100" height="1011"/></svg>`;
  const svgExRight = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" preserveAspectRatio="none" viewBox="0 0 100 890"><rect fill="#000" x="0" y="0" width="100" height="890"/></svg>`;

  const contentRef = useRef<HTMLDivElement>();

  const nextArticle = {
    image: getImage(next?.heroImage),
    slug: next && next?.slug && `/blog/${next.slug}`,
    title: next?.title,
    excerpt: next?.excerpt,
  };
  const prevArticle = {
    image: getImage(prev?.heroImage),
    slug: prev && prev?.slug && `/blog/${prev.slug}`,
    title: prev?.title,
    excerpt: prev?.excerpt,
  };

  return (
    <StyledArticle
      maskImgBg={`data:image/svg+xml,${encodeURIComponent(
        svgTopPath?.svg?.content,
      )}`}
      maskImgLeft={`data:image/svg+xml,${encodeURIComponent(svgExLeft)}`}
      maskImgRight={`data:image/svg+xml,${encodeURIComponent(svgExRight)}`}
      ref={contentRef}
    >
      <Layout
        location={location}
        coverImage={hero}
        bottomSvg={svgBottomPath?.svg?.content}
        variant={4}
        desktopHeading={
          <div>
            <Link to="/blog/" className="back-button">
              <FontAwesomeIcon icon={faArrowLeftLong} />
              <span className="back-content">Back to blog</span>
            </Link>
            <Content restrictWidth className="hero-container">
              <Text type="title1" noRhythem className="main-title">
                {title}
              </Text>
            </Content>
          </div>
        }
        desktopBody={
          <ArticleTemplate
            isPreview="false"
            content={content}
            rawContent={markdownContent}
            title={title}
            slug={slug}
            timeToRead={timeToRead}
            date={updateDate}
            author={author}
            tags={tags}
            cover={hero}
            nextArticle={nextArticle}
            prevArticle={prevArticle}
            metaDescription={metaDescription}
            contentRef={contentRef}
            relatedArticles={related}
          />
        }
        desktopCta={
          <Content>
            <CallToAction
              hookId={ctaHookId}
              icon={ctaIcon}
              title={ctaTitle}
              body={ctaBody.ctaBody}
              maxWidth="100%"
              className="cta"
            />
            <Social />
          </Content>
        }
      >
        <Seo
          title={title}
          metaTitle={metaTitle}
          metaDesc={metaDescription}
          cover={hero}
          slug={slug}
          isArticlePage
          templateKey={templateKey}
        />
        <Main showScrrollBackground={false}>
          <ArticleTemplate
            isPreview="false"
            content={content}
            rawContent={markdownContent}
            title={title}
            slug={slug}
            timeToRead={timeToRead}
            date={updateDate}
            author={author}
            tags={tags}
            cover={hero}
            nextArticle={nextArticle}
            prevArticle={prevArticle}
            metaDescription={metaDescription}
            contentRef={contentRef}
            relatedArticles={related}
          />
        </Main>
        <Aside disableBackground={false}>
          <Newsletter id="newsletter_article" />

          {/* <Categories tags={tags} /> */}
        </Aside>
      </Layout>
    </StyledArticle>
  );
};

export default ArticlePage;

export const articleQuerys = graphql`
  query ArticleBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    contentfulArticle(slug: { eq: $slug }) {
      templateKey
      title
      slug
      tags
      updateDate(formatString: "MMMM Do, YYYY")
      body {
        childMarkdownRemark {
          html
        }
        body
      }
      author {
        slug
        name
        image {
          gatsbyImageData(
            width: 105
            height: 105
            quality: 85
            placeholder: BLURRED
          )
        }
      }
      heroImage {
        gatsbyImageData(layout: FULL_WIDTH, quality: 85, placeholder: BLURRED)
      }
      metaTitle
      metaDescription
      timeToRead
    }
    related: allContentfulArticle(
      sort: { fields: [publishDate], order: DESC }
      limit: 6
    ) {
      edges {
        node {
          templateKey
          title
          slug
          id
          timeToRead
          tags
          createDate(formatString: "MMMM Do, YYYY")
          publishDate(formatString: "MMMM Do, YYYY")
          heroImage {
            description
            gatsbyImageData(width: 344, height: 180, quality: 100)
          }
          excerpt
          author {
            name
            slug
            image {
              gatsbyImageData(
                width: 40
                height: 40
                quality: 100
                placeholder: BLURRED
              )
            }
          }
        }
      }
    }
    articlePage: contentfulPage(slug: { eq: "article" }) {
      metaTitle
      metaDescription
      svgTopPath {
        svg {
          content
        }
      }
      svgBottomPath {
        svg {
          content
        }
      }
      ctaTitle
      ctaHookId
      ctaIcon {
        gatsbyImageData(width: 48, quality: 100, placeholder: BLURRED)
      }
      ctaBody {
        ctaBody
      }
    }
  }
`;
