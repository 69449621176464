import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image';
import { Text } from '../Text';

interface IArticleThumb {
  className?: string;
  title: string;
  children: any;
  thumbnail: ImageDataLike;
  excerpt: string;
  navPosition: 'left' | 'right';
}

export const StyledArticleThumb = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  justify-content: flex-start;
  background-color: ${({ theme }) => theme.colors.blue_1_65};
  padding: ${rem(`24px`)};
  border-radius: ${rem(`12px`)};
  &:hover {
    .articleThumb__title,
    .articleThumb__navigation {
      color: ${({ theme }) => theme.colors.pink_1};
      transition: color 250ms ease-in-out;
    }
    .articleThumb__image {
      filter: brightness(1.2);
      transition: filter 250ms ease-in-out;
    }
  }
  .articleThumb__container {
    display: flex;
    flex: 0 1 auto;
  }
  .articleThumb__image {
    flex: 1 0 auto;
    border-radius: ${rem(`8px`)};
    transition: filter 250ms ease-in-out;
  }
  .articleThumb__content {
    flex: 0 1 auto;
    min-width: 0;
    margin-left: ${rem(`16px`)};
  }
  .articleThumb__title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .articleThumb__excerpt {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    ${({ theme }) => theme.breakpoints.desktop`
      -webkit-line-clamp: 3;
    `}
  }
  .articleThumb__navigation {
    display: flex;
    margin-top: ${rem(12)};
    align-items: center;
    transition: color 250ms ease-in-out;
    &--left {
      justify-content: flex-start;
      span {
        margin-left: ${rem(4)};
      }
    }
    &--right {
      justify-content: flex-end;
      span {
        margin-right: ${rem(4)};
      }
    }
  }
`;

export const ArticleThumb = ({
  className,
  title,
  children,
  thumbnail,
  excerpt,
  navPosition = `left`,
}: IArticleThumb) => {
  const image = getImage(thumbnail);

  return (
    <StyledArticleThumb className={className}>
      <div className="articleThumb__container">
        <GatsbyImage image={image} alt="" className="articleThumb__image" />
        <div className="articleThumb__content">
          <Text
            className="articleThumb__title"
            color="gray_3"
            type="title4"
            tag="h5"
            noRhythem
          >
            {title}
          </Text>
          <Text
            className="articleThumb__excerpt"
            color="gray_3"
            type="body3"
            tag="p"
            noRhythem
          >
            {excerpt}
          </Text>
        </div>
      </div>

      <Text
        color="gray_3"
        type="title4"
        tag="h6"
        noRhythem
        className={`articleThumb__navigation articleThumb__navigation--${
          navPosition === `left` ? `left` : `right`
        }`}
      >
        {children}
      </Text>
    </StyledArticleThumb>
  );
};
