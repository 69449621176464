import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTwitter,
  faFacebookF,
  faLinkedin,
  faWhatsapp,
} from '@fortawesome/free-brands-svg-icons';

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookShareCount,
} from 'react-share';

import { faShareFromSquare } from '@fortawesome/pro-light-svg-icons';
import config from '../../../meta/config';
import { StyledSocialShare, StyledShareCount } from './styles';
import { WidgetTitle } from '../WidgetTitle';
import { Button } from '../Button';

export interface IShare {
  heading: string;
  title: string;
  slug: string;
  excerpt: string;
  className?: string;
}

export const Share = ({ heading, title, slug, excerpt, className }: IShare) => {
  const url = `${config.siteUrl}/${slug}`;
  const filter = (count) => (count > 0 ? count : ``);
  return (
    <StyledSocialShare className={className}>
      <div className="share__header">
        <WidgetTitle icon={faShareFromSquare}>{heading}</WidgetTitle>
      </div>
      <div className="share__content">
        {/* <Button size="small" disabled={false}> */}
        {/*   <RedditShareButton url={url} title={title}> */}
        {/*     <FontAwesomeIcon size="lg" icon={faRedditAlien} /> */}
        {/*     <RedditShareCount url={url}> */}
        {/*       {(count) => <StyledShareCount>{filter(count)}</StyledShareCount>} */}
        {/*     </RedditShareCount> */}
        {/*   </RedditShareButton> */}
        {/* </Button> */}

        <Button size="small" disabled={false}>
          <TwitterShareButton
            url={url}
            title={title}
            hashtags={[`Domusnetwork`]}
            related={[`@ilia_luk`]}
          >
            <FontAwesomeIcon size="lg" icon={faTwitter} />
          </TwitterShareButton>
        </Button>

        <Button size="small" disabled={false}>
          <FacebookShareButton url={url} hashtag="Domusnetwork">
            <FontAwesomeIcon size="lg" icon={faFacebookF} />
            <FacebookShareCount url={url}>
              {(count) => <StyledShareCount>{filter(count)}</StyledShareCount>}
            </FacebookShareCount>
          </FacebookShareButton>
        </Button>

        <Button size="small" disabled={false}>
          <LinkedinShareButton
            url={url}
            title={title}
            summary={excerpt}
            source="Domusnetwork"
          >
            <FontAwesomeIcon size="lg" icon={faLinkedin} />
          </LinkedinShareButton>
        </Button>

        {/* <Button size="small" disabled={false}> */}
        {/*   <TelegramShareButton url={url} title={title}> */}
        {/*     <FontAwesomeIcon size="lg" icon={faTelegram} /> */}
        {/*   </TelegramShareButton> */}
        {/* </Button> */}

        <Button size="small" disabled={false}>
          <WhatsappShareButton url={url} title={title}>
            <FontAwesomeIcon size="lg" icon={faWhatsapp} />
          </WhatsappShareButton>
        </Button>
      </div>
    </StyledSocialShare>
  );
};
