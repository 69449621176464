import React from 'react';
import { Link } from 'gatsby';
import { StyledArticleNavigation } from './styles';
import { Arrow } from '../Arrow';

interface IArticleNavigation {
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  previous?: boolean;
  next?: boolean;
  toSlug: string;
}

function arrows(arrowsMap: object, isDisabled: boolean): React.ReactElement {
  const arrowType: string = Object.keys(arrowsMap).filter(
    (key) => arrowsMap[key] === true,
  )[0];
  switch (arrowType) {
    case `previous`:
      return <Arrow disabled={isDisabled} left />;
    case `next`:
      return <Arrow disabled={isDisabled} right />;
    default:
      return <Arrow disabled />;
  }
}

export const ArticleNavigation = ({
  onClick,
  disabled = false,
  className,
  previous = false,
  next = false,
  toSlug,
}: IArticleNavigation) => {
  const disabledclass = disabled ? `articleNavigation--disabled` : ``;
  const arrowsMap = { previous, next };
  return (
    <StyledArticleNavigation
      toSlug={toSlug && toSlug.replace(`/blog/`, ``)}
      next={next}
      className={`${disabledclass} ${className}`}
      onClick={!disabled ? onClick : null}
    >
      <Link aria-label={toSlug} to={toSlug}>
        {arrows(arrowsMap, disabled)}
      </Link>
    </StyledArticleNavigation>
  );
};
