import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { StyledHero } from './styles';
import { ArticleNavigation } from '../ArticleNavigation';
import { Text } from '../Text';

interface IHero {
  imageSrc: any;
  alt?: string;
  title?: string;
  className?: string;
  nextArticleSlug?: string;
  prevArticleSlug?: string;
}

export const Hero = ({
  imageSrc,
  alt,
  title,
  className,
  nextArticleSlug,
  prevArticleSlug,
}: IHero) => (
  <StyledHero className={className}>
    <div className="hero__image-container">
      <GatsbyImage image={imageSrc} alt={alt} className="hero__image" />
    </div>
    {prevArticleSlug ? (
      <ArticleNavigation
        toSlug={prevArticleSlug}
        previous
        className="hero__previous"
      />
    ) : null}
    {nextArticleSlug ? (
      <ArticleNavigation toSlug={nextArticleSlug} next className="hero__next" />
    ) : null}
    {title ? (
      <Text className="hero__text" noRhythem type="title1" tag="h1">
        {title}
      </Text>
    ) : null}
  </StyledHero>
);
