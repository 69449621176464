import React from 'react';
import { faSignsPost } from '@fortawesome/pro-light-svg-icons';
import styled from 'styled-components';
import { rem } from 'polished';
import { WidgetTitle } from '../WidgetTitle/index';
import { PostCard } from '../PostCard';

interface IRelatedArticles {
  articles: {
    title: string;
    content: string;
    imageUrl: string;
    isVideo: boolean;
    link: string;
    author: { name: string; slug: string; image: any };
    createDate: string;
    excerpt: string;
    heroImage: any;
    id: string;
    publishDate: string;
    createdDate: string;
    slug: string;
    tags: string[];
    templateKey: string;
    timeToRead: number;
  }[];
}

const StyledRelatedArticles = styled.div`
  overflow: hidden;
  width: 100%;
  margin-bottom: ${rem(`48px`)};
  .relatedArticles__header {
    display: flex;
    justify-content: center;
    margin-bottom: ${rem(`24px`)};
  }
  .relatedArticles__content {
    display: grid;
    overflow: hidden;
    gap: ${rem(`18px`)};
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 1fr;
    ${({ theme }) => theme.breakpoints.phablet`
    gap: ${rem(`20px`)};
    grid-template-columns: repeat(2, 1fr);
  `}
    ${({ theme }) => theme.breakpoints.tablet`
    gap: ${rem(`24px`)};
    grid-template-columns: repeat(3, 1fr);
  `}
  }
`;

export const RelatedArticles = ({ articles }: IRelatedArticles) => (
  <StyledRelatedArticles>
    <div className="relatedArticles__header">
      <WidgetTitle icon={faSignsPost}>Recommended</WidgetTitle>
    </div>
    <div className="relatedArticles__content">
      {articles.map(({ publishDate, ...rest }) => {
        return (
          <PostCard vertical date={publishDate} {...rest} key={rest.title} />
        );
      })}
    </div>
  </StyledRelatedArticles>
);
