import React from 'react';
import { DiscussionEmbed } from 'disqus-react';
import { faComments } from '@fortawesome/pro-light-svg-icons';
import config from '../../../meta/config';
import { StyledDisqus } from './styles';
import { WidgetTitle } from '../WidgetTitle';

interface IDisqus {
  title: string;
  slug: string;
  className?: string;
  heading: string;
}

export const Disqus = ({ heading, title, slug, className }: IDisqus) => {
  if (!config.disqusShortname) {
    return null;
  }
  const disqusConfig = {
    title,
    url: `${config.siteUrl}/${slug}`,
    identifier: title,
  };
  return (
    <StyledDisqus className={className}>
      <div className="disqus__header">
        <WidgetTitle icon={faComments}>{heading}</WidgetTitle>
      </div>
      <div className="disqus__content">
        <DiscussionEmbed
          shortname={config.disqusShortname}
          config={disqusConfig}
        />
      </div>
    </StyledDisqus>
  );
};
