import styled from 'styled-components';
import { rem } from 'polished';

export const StyledSocialShare = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .share__header {
    display: flex;
    margin-bottom: ${rem(`8px`)};
    pointer-events: none;
    ${({ theme }) => theme.breakpoints.desktop`
      justify-content: center;
      margin-bottom: ${rem(`36px`)};
    `}
  }
  .share__content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    margin-bottom: ${rem(`24px`)};
    font-size: ${rem(`36px`)};
    width: ${rem(`280px`)};
    & .SocialMediaShareButton {
      margin: 5px 15px;
      transition: color 500ms ease-in-out;
      cursor: pointer;
      ${({ theme }) => theme.breakpoints.tablet`
      &:hover {
        transition: color 500ms ease-in-out;
        color: ${theme.colors.pink_1};
      }
    `}
    }
    & > button {
      width: ${rem(`48px`)};
      height: ${rem(`48px`)};
      padding: 0;
    }
    & > button > span {
      display: block;
      font-size: ${rem(`24px`)};
    }
  }
`;

export const StyledShareCount = styled.div`
  text-align: center;
`;
