import styled from 'styled-components';
import { rem } from 'polished';

export const StyledHero = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  z-index: 9;
  margin: 0 -${rem(`16px`)} ${rem(`36px`)};
  max-height: ${rem(`400px`)};
  .hero__image-container {
    width: 100%;
    height: auto;
    z-index: 9;
  }
  .hero__image {
    width: 100%;
    object-fit: cover;
    object-position: center;
    height: auto;
  }
  .hero__next,
  .hero__previous {
    position: absolute;
    z-index: 999;
  }
  .hero__next {
    right: 0;
  }
  .hero__previous {
    left: 0;
  }
  .hero__text {
    z-index: 999;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: absolute;
    right: 48px;
    left: 48px;
    margin: auto;
    text-align: center;
    line-height: ${({ theme }) => theme.rhythmSizing(10)}rem;
    padding-bottom: ${rem(`10px`)};
    color: ${({ theme }) => theme.colors.white_1};
    text-shadow: 3px 3px 4px ${({ theme }) => theme.colors.blue_1_65};
  }
`;
